var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-12 pb-12"},[_c('v-card',{staticClass:"transparent mx-auto",attrs:{"flat":"","max-width":"900"}},[_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","max-width":"200","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-12 mb-5",attrs:{"text":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1)],1),_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.filteredRecords,"item-key":"uniqueCode","items-per-page":_vm.filteredRecords.length,"sort-by":['modified'],"sort-desc":[true, false, false],"multi-sort":"","group-by":"modified","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"2"}},[_c('v-icon',{attrs:{"color":isOpen ? '#900' : '#999'},on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-folder-open-outline' : 'mdi-folder-outline')+" ")]),_vm._v(" "+_vm._s(items[0].modified)+" ")],1)]}},{key:"item.customer.uniqueCode",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.clickCallback(item.customer)}}},[_vm._v(" "+_vm._s(item.customer.uniqueCode)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('BookingRecordStatusButton',{attrs:{"record":item,"activated":_vm.activated,"suspended":_vm.suspended,"canceled":_vm.canceled,"resumed":_vm.resumed,"search":_vm.search},on:{"update:record":function($event){item=$event},"update:activated":function($event){_vm.activated=$event},"update:suspended":function($event){_vm.suspended=$event},"update:canceled":function($event){_vm.canceled=$event},"update:resumed":function($event){_vm.resumed=$event}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.serviceName)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }